
import NeedGuidance from '@/components/home/NeedGuidance.vue'
import Footer from '@/components/home/Footer.vue'
import TvPlans from '@/components/internettv/TvPlans.vue'
import InternetTvFeatures from '@/components/internettv/InternetTvFeatures.vue'
import { onMounted, ref } from 'vue'

export default {
    name: "postpaid-plans",
    components: {
        'jt-tv-plans': TvPlans,
        'jt-internet-tv-features': InternetTvFeatures,
        'jt-need-guidance': NeedGuidance,
        'jt-footer': Footer
    },
    setup() {
        const mode = ref('M');

        const setPageTitle = () => {
            document.title = 'OTT Platform & Tv chanels';
        };

        onMounted(() => {
            setPageTitle();
        });

        return { mode };
    }
}
