import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createBlock(_component_v_carousel, {
    interval: "10000",
    cycle: "",
    "hide-delimiter-background": "",
    "show-arrows": false
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, (im, i) => {
        return (_openBlock(), _createBlock(_component_v_carousel_item, {
          key: i,
          "reverse-transition": "fade-transition",
          transition: "fade-transition",
          src: im
        }, null, 8, ["src"]))
      }), 128))
    ]),
    _: 1
  }))
}