
import BookNowButton from '@/components/plan/BookNowButton.vue'

export default {
    name: "plan-details",
    components: {
        'jt-book-now-button': BookNowButton
    },
    props: {
        item: {
            type: Object
        },
        show: {
            type: Boolean
        }
    },
    setup(props: any, context: any) {
        const closePopup = (() => {
            context.emit('closePopup', false)
        })

        const getBookNowStatus = (() => {
            return true;
        })

        return { closePopup, getBookNowStatus };
    }
}
