import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jt_image_slider = _resolveComponent("jt-image-slider")!
  const _component_jt_our_services = _resolveComponent("jt-our-services")!
  const _component_jt_popular_plans = _resolveComponent("jt-popular-plans")!
  const _component_jt_view_pay_bill = _resolveComponent("jt-view-pay-bill")!
  const _component_jt_book_today = _resolveComponent("jt-book-today")!
  const _component_jt_measure_speed = _resolveComponent("jt-measure-speed")!
  const _component_jt_tv_smart = _resolveComponent("jt-tv-smart")!
  const _component_jt_need_guidance = _resolveComponent("jt-need-guidance")!
  const _component_jt_footer = _resolveComponent("jt-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_jt_image_slider),
    _createVNode(_component_jt_our_services),
    _createVNode(_component_jt_popular_plans),
    _createVNode(_component_jt_view_pay_bill),
    _createVNode(_component_jt_book_today),
    _createVNode(_component_jt_measure_speed),
    _createVNode(_component_jt_tv_smart),
    _createVNode(_component_jt_need_guidance),
    _createVNode(_component_jt_footer)
  ], 64))
}