<template>
    <v-timeline side="end" align="start">
        <v-timeline-item dot-color="red" size="small">
            <div class="d-flex">
                <div>
                    <div class="color-blue"><b>Jtel Contact</b></div>
                    <div class="sub-text mt-2">
                        admin@jtel.in
                    </div>
                </div>
            </div>
        </v-timeline-item>
        <v-timeline-item dot-color="red" size="small">
            <div class="d-flex">
                <div>
                    <div class="color-blue"><b>Postal address</b></div>
                    <div class="sub-text mt-2">
                        Johnson Tele Marketers Pvt Ltd <br>
                        NH-47, Thiruvananthapuram Main Road<br>
                        Marthandam, Tamil Nadu<br>
                        India - 629 165

                    </div>
                </div>
            </div>
        </v-timeline-item>
        <v-timeline-item dot-color="red" size="small">
            <div class="d-flex">
                <div>
                    <div class="color-blue"><b>Our Branches</b></div>
                    <div class="sub-text mt-2">
                        KSV Building - 2nd Floor <br>
                        Opp. St.Marry Hr. Sec. School<br>
                        Colachel, Tamil Nadu<br>
                        India - 629 251
                    </div>
                </div>
            </div>
        </v-timeline-item>
        <v-timeline-item dot-color="red" size="small">
            <div class="d-flex">
                <div>
                    <div class="sub-text mt-2">
                        5-186 A/4 Rexy Complex <br>
                        Opp. Post Office Road<br>
                        Karungal, Tamil Nadu<br>
                        India - 629 157
                    </div>
                </div>
            </div>
        </v-timeline-item>
        <v-timeline-item dot-color="red" size="small">
            <div class="d-flex">
                <div>
                    <div class="sub-text mt-2">
                        APN Plaza, First Floor <br>
                        Chettikulam Junction<br>
                        Nagercoil, Tamil Nadu<br>
                        India - 629 002
                    </div>
                </div>
            </div>
        </v-timeline-item>
    </v-timeline>
</template>

<style scoped>
.sub-text {
    font-size: 14px;
    font-weight: 500;
}
</style>