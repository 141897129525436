import planService from '@/services/planService';
import { ref, watch } from 'vue';
import BookNowButton from '@/components/plan/BookNowButton.vue'

export default {
    name: "all-prepaid-plans",
    props: {
        mode: { type: String, default: 'M' }
    },
    components: {
        'jt-book-now-button': BookNowButton
    },
    setup(props: any) {
        const plans = ref<any>([]);

        const getPlansData = (() => {
            if (props.mode == 'M')
                plans.value = planService.getAllPrepaidPlans() as any;
            else if (props.mode == 'H')
                plans.value = planService.getHalfYearlyPlans() as any;
            else if (props.mode == 'Y')
                plans.value = planService.getYearlyPlans() as any;
        });

        getPlansData();

        watch(props, () => {
            getPlansData();
            console.log(plans)
        });

        const getValidity = (() => {
            if (props.mode == 'M')
                return 'Month';
            else if (props.mode == 'H')
                return '6 Months';
            else if (props.mode == 'Y')
                return 'Year'
        })

        const getBookNowStatus = (() => {
            return true;
        })

        return { plans, getPlansData, getValidity, getBookNowStatus };
    }
}
