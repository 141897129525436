
import planService from '@/services/planService';
import BookNowButton from '@/components/plan/BookNowButton.vue'

export default {
    name: "all-postpaid-plans",
    components: {
        'jt-book-now-button': BookNowButton
    },
    setup() {
        const plans = planService.getAllPostpaidPlans();

        const getBookNowStatus = (() => {
            return true;
        })

        return { plans, getBookNowStatus };
    }
}
