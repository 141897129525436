import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-center mb-0 mb-md-10" }
const _hoisted_2 = { class: "d-flex justify-center mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jt_plan_item = _resolveComponent("jt-plan-item")!
  const _component_v_slide_group_item = _resolveComponent("v-slide-group-item")!
  const _component_v_slide_group = _resolveComponent("v-slide-group")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_slide_group, { "show-arrows": "" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.plans, (plan, i) => {
            return (_openBlock(), _createBlock(_component_v_slide_group_item, { key: i }, {
              default: _withCtx(() => [
                _createVNode(_component_jt_plan_item, {
                  class: "mr-6",
                  item: plan
                }, null, 8, ["item"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/fiber-broadband-plans-home" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "btn-border px-7 h-4rem",
            style: {"height":"50px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("View all plans")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}