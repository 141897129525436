

export default {
    name: "plan-item",
    props: {
        item: {
            type: Object
        }
    }
}
