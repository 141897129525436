
import { ref } from 'vue';
import SupportForm from "@/components/general/SupportForm.vue";

export default {
    components: {
        "jt-support-form": SupportForm
    },
    setup() {
        const showSupport = ref(false);

        const closeBookNow = (() => {
            showSupport.value = false;
        });

        return { showSupport, closeBookNow };
    }
}
