
import { ref } from 'vue';

export default {
    name: "ImageSlider",
    setup() {
        const items = ref([{
            src: require('../../assets/services/broadband-over1.png'),
            srcHover: require('../../assets/services/broadband-over2.png'),
            name: 'Broadband',
            hover: false,
            link: '/broadband'
        },
        {
            src: require('../../assets/services/Leased-Line1.png'),
            srcHover: require('../../assets/services/Leased-Line2.png'),
            name: 'Leased Line',
            hover: false,
            link: '/smart-internet-leased-line'
        },
        {
            src: require('../../assets/services/broadband-ip-phone-over1.png'),
            srcHover: require('../../assets/services/broadband-ip-phone-over2.png'),
            name: 'Internet Phone',
            hover: false,
            link: '/internet-phone'
        },
        {
            src: require('../../assets/services/ip-tv-over-1.png'),
            srcHover: require('../../assets/services/ip-tv-over-2.png'),
            name: 'Internet TV',
            hover: false,
            link: '/tv'
        }]);

        return { items };
    }
}
