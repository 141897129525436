
import NeedGuidance from '@/components/home/NeedGuidance.vue'
import Footer from '@/components/home/Footer.vue'
import { onMounted, ref } from 'vue';

export default {
    name: "postpaid-plans",
    components: {
        'jt-need-guidance': NeedGuidance,
        'jt-footer': Footer
    },
    setup() {
        const mode = ref('M');

        const setPageTitle = () => {
            document.title = 'FAQ';
        };

        onMounted(() => {
            setPageTitle();
        });

        return { mode };
    }
}
