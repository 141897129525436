import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import PostpaidPlans from "../views/PostpaidPlans.vue";
import PrepaidPlans from "../views/PrepaidPlans.vue";
import AllPlans from "../views/AllPlans.vue";
import LeasedLine from "../views/LeasedLine.vue";
import ContactUs from "../views/ContactUs.vue";
import InternetTv from "../views/InternetTv.vue";
import InternetPhone from "../views/InternetPhone.vue";
import FaqPage from "../views/FaqPage.vue";
import Support from "../views/Support.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/broadband",
    name: "broadband",
    component: Home,
  },
  {
    path: "/fiber-postpaid-plans-home",
    name: "postpaid",
    component: PostpaidPlans,
  },
  {
    path: "/fiber-prepaid-plans-home",
    name: "prepaid",
    component: PrepaidPlans,
  },
  {
    path: "/fiber-broadband-plans-home",
    name: "all",
    component: AllPlans,
  },
  {
    path: "/smart-internet-leased-line",
    name: "leased-line",
    component: LeasedLine,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/tv",
    name: "internet-tv",
    component: InternetTv,
  },
  {
    path: "/internet-phone",
    name: "internet-phone",
    component: InternetPhone,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqPage,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;
