
import PlanDetails from '@/components/plan/PlanDetails.vue'
import BookNowButton from '@/components/plan/BookNowButton.vue'
import { ref } from 'vue';

export default {
    name: "plan-item",
    components: {
        "jt-plan-details": PlanDetails,
        'jt-book-now-button': BookNowButton
    },
    props: {
        item: {
            type: Object
        }
    },
    setup() {
        const show = ref(false);
        const showBookNow = ref(false);

        const closePopup = (() => {
            show.value = false;
        })

        const closeBookNow = (() => {
            showBookNow.value = false;
        })

        const getBookNowStatus = (() => {
            showBookNow.value = true;
        })


        return { show, showBookNow, closePopup, closeBookNow, getBookNowStatus };
    }
}
