<template>
    <div class="mt-md-n4">
        <div style="font-size: 20px; font-weight: 800;">Connect with us</div>
        <div>
            <a target="_blank" href="https://twitter.com/Jtelindia">
                <v-avatar color="info" class="mr-2">
                    <v-icon icon="mdi-twitter"></v-icon>
                </v-avatar>
            </a>
            <v-avatar color="info" class="mr-2">
                <v-icon icon="mdi-instagram"></v-icon>
            </v-avatar>
            <a target="_blank" href="https://www.facebook.com/JtelColachel">
                <v-avatar color="info" class="mr-2">
                    <v-icon icon="mdi-facebook"></v-icon>
                </v-avatar>
            </a>
            <v-avatar color="info" class="mr-2">
                <v-icon icon="mdi-youtube"></v-icon>
            </v-avatar>
            <a target="_blank" href="https://www.linkedin.com/in/jtelindia/">
                <v-avatar color="info">
                    <v-icon icon="mdi-linkedin"></v-icon>
                </v-avatar>
            </a>
        </div>
        <div style="font-size: 20px; font-weight: 800;" class="mt-2">Download Jtel</div>
        <div class="d-flex">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=app.customerportal.jtel1">
                <img :src="require(`../../assets/google-play.png`)" height="100" width="120" class="mr-2 my-n8" />
            </a>
            <img :src="require(`../../assets/app-store.png`)" height="68" width="200" class="mr-2 my-n4 ml-n9" />
        </div>
        <div style="font-size: 20px; font-weight: 800;" class="mt-2">Toll-Free</div>
        <div style="font-size: 28px; font-weight: 900;" class="color-blue">1800-123-7476</div>
    </div>
</template>