
import mailService from '@/services/mailService';
import { ref } from 'vue';

export default {
    name: "plan-details",
    props: {
        item: {
            type: Object
        }
    },
    setup() {
        const submitted = ref(false);
        const success = ref(false);
        const detail = ref({
            name: '',
            phone: '',
            email: '',
            subject: '',
            address: '',
            message: ''
        })

        const sendMail = (() => {
            submitted.value = true;
            success.value = false;

            if (detail.value.phone.trim() && detail.value.email.trim() && isValidPh.value && isValidEmail.value) {
                const subject = `Contact request from ${detail.value.name ?? detail.value.phone}`;
                const body = `Name - ${detail.value.name} \n
                          Phone number - ${detail.value.phone} \n
                          Email - ${detail.value.email} \n
                          Address - ${detail.value.address} \n
                          Subject - ${detail.value.subject} \n
                          Message - ${detail.value.message} \n`;

                mailService.sendMail(detail.value.email, subject, body);

                success.value = true;

                detail.value = {
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    address: '',
                    message: ''
                };

                submitted.value = false;
            }            
        });

        const isValidPh = ref(false);
        const validatePhoneNumber = (() => {
            debugger;
            const validationRegex = "^[0-9_.+\\- ]{10,20}$";
            if (detail.value.phone.match(validationRegex)) {
                isValidPh.value = true;
            } else {
                isValidPh.value = false;
            }
        });

        const isValidEmail = ref(false);
        const validateEmail = (() => {
            debugger;
            const validationRegex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
            if (detail.value.email.match(validationRegex)) {
                isValidEmail.value = true;
            } else {
                isValidEmail.value = false;
            }
        });

        return { detail, sendMail, submitted, success, validatePhoneNumber, isValidPh, validateEmail, isValidEmail };
    }
}
