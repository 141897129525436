import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58298e50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center my-4 zindex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jt_support_request = _resolveComponent("jt-support-request")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_jt_support_request)
  ]))
}