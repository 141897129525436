import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jt_tv_plan_item = _resolveComponent("jt-tv-plan-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "d-flex justify-center gray-bg pb-15 px-0 px-md-15" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.plans, (plan, i) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          class: "d-flex justify-center",
          key: i
        }, {
          default: _withCtx(() => [
            _createVNode(_component_jt_tv_plan_item, { item: plan }, null, 8, ["item"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}