
export default {
    name: "ImageSlider",
    setup() {
        const isMobile = () => {
            return screen.width <= 768;
        }
        let images: string[];

        if (isMobile()) {
            images = [require('../../assets/slider/1m.png'),
            require('../../assets/slider/2m.png'),
            require('../../assets/slider/3m.png')];
        }
        else {
            images = [require('../../assets/slider/1.png'),
            require('../../assets/slider/2.png'),
            require('../../assets/slider/3.png')];
        }

        return { images };
    }
}
