import data from '../assets/data/plans.json';
import prepaid from '../assets/data/halfyearly.json';
import tvplans from '../assets/data/tvplans.json';

export default {
    getPopularPlans() {
        return data.filter(x => x.isPopular == true);
    },
    getAllPostpaidPlans() {
        return data.filter(x => x.mode == 1);
    },
    getAllPrepaidPlans() {
        return data.filter(x => x.mode == 2);
    },
    getHalfYearlyPlans() {
        return prepaid.filter(x => x.mode == 'H');
    },
    getYearlyPlans() {
        return prepaid.filter(x => x.mode == 'Y');
    },
    getTvPlans() {
        return tvplans;
    }
}