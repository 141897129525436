import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "leased-card my-10 py-10" }
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = {
  style: {"font-size":"14px"},
  class: "px-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "mx-8" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item, i) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "6",
            md: "3",
            class: "text-center cursor-pointer",
            key: i
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                src: item.hover ? item.srcHover : item.src,
                height: "125",
                onMouseover: ($event: any) => (item.hover = true),
                onMouseleave: ($event: any) => (item.hover = false)
              }, null, 8, ["src", "onMouseover", "onMouseleave"]),
              _createElementVNode("h4", _hoisted_2, _toDisplayString(item.name), 1),
              _createElementVNode("div", _hoisted_3, _toDisplayString(item.details), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}