
import TvPlanItem from "@/components/internettv/TvPlanItem.vue";
import planService from '@/services/planService';

export default {
    name: "tv-plan-items",
    components: {
        "jt-tv-plan-item": TvPlanItem
    },
    setup() {
        const plans = planService.getTvPlans();

        return { plans };
    }
}
