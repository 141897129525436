
import { ref } from 'vue';

export default {
    name: "ImageSlider",
    setup() {
        const items = ref([{
            src: require('../../assets/internetphone/ip-1.png'),
            srcHover: require('../../assets/internetphone/ip-1h.png'),
            hover: false,
            name: 'Crystal clear voice',
            details: ""
        },
        {
            src: require('../../assets/internetphone/ip-2.png'),
            srcHover: require('../../assets/internetphone/ip-2h.png'),
            name: 'International call',
            hover: false,
            details: ""
        },
        {
            src: require('../../assets/internetphone/ip-3.png'),
            srcHover: require('../../assets/internetphone/ip-3h.png'),
            name: 'Any WiFi connect',
            hover: false,
            details: ""
        },
        {
            src: require('../../assets/internetphone/ip-4.png'),
            srcHover: require('../../assets/internetphone/ip-4h.png'),
            name: 'No app required',
            hover: false,
            details: ""
        }]);

        return { items };
    }
}
