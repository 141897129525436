
import { ref } from 'vue';
import PostpaidPlans from '@/components/plan/PostpaidPlans.vue'
import { PlanType } from '@/models/planType';

export default {
    name: "ImageSlider",
    components: {
        'jt-postpaid-plans': PostpaidPlans
    },
    setup() {
        const plan = ref<PlanType>(PlanType.Postpaid);

        return { plan };
    }
}
