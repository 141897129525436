
import { ref } from 'vue';

export default {
    name: "ImageSlider",
    setup() {
        const items = ref([{
            src: require('../../assets/leasedline/l-1.png'),
            name: 'Dedicated bandwidth',
            details: "Fastest and consistent internet speeds"
        },
        {
            src: require('../../assets/leasedline/l-2.png'),
            name: 'Uncongested core',
            details: "Lowest latency and packet drop"
        },
        {
            src: require('../../assets/leasedline/l-3.png'),
            name: 'Multi-path redundancy',
            details: "24x7 uninterrupted network connectivity"
        },
        {
            src: require('../../assets/leasedline/l-4.png'),
            name: 'End-to-end SLA',
            details: "Committed uptime, latency and packet loss"
        }]);

        return { items };
    }
}
