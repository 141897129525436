
import { ref } from 'vue';
import { PlanType } from '@/models/planType';
import ConnectUs from "@/components/home/ConnectUs.vue";

export default {
    name: "ImageSlider",
    components: {
        'jt-connect-us': ConnectUs
    },
    setup() {
        const plan = ref<PlanType>(PlanType.Postpaid);

        const items = ref([{
            src: require('../../assets/services/broadband-over1.png'),
            srcHover: require('../../assets/services/broadband-over2.png'),
            name: 'Broadband',
            hover: false
        },
        {
            src: require('../../assets/services/Leased-Line1.png'),
            srcHover: require('../../assets/services/Leased-Line2.png'),
            name: 'Leased Line',
            hover: false
        },
        {
            src: require('../../assets/services/broadband-ip-phone-over1.png'),
            srcHover: require('../../assets/services/broadband-ip-phone-over2.png'),
            name: 'Internet Phone',
            hover: false
        },
        {
            src: require('../../assets/services/ip-tv-over-1.png'),
            srcHover: require('../../assets/services/ip-tv-over-2.png'),
            name: 'Internet TV',
            hover: false
        }]);

        return { items, plan };
    }
}
