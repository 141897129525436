
import mailService from '@/services/mailService';
import { ref } from 'vue';

export default {
    name: "plan-details",
    props: {
        item: {
            type: Object
        }
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const success = ref(false);
        const detail = ref({
            name: '',
            phone: '',
            email: '',
            supportType: 'Broadband',
            category: 'Billing',
            message: ''
        })

        const closePopup = (() => {
            context.emit('closePopup', false)
        })

        const sendMail = (() => {
            submitted.value = true;

            if (detail.value.phone.trim() && detail.value.email.trim()) {
                const subject = `Support request from ${detail.value.phone}`;
                const body = `Connection Name - ${detail.value.name} \n
                          Phone number - ${detail.value.phone} \n
                          Email - ${detail.value.email} \n
                          Support Type - ${detail.value.supportType} \n
                          Message - ${detail.value.message} \n`;

                mailService.sendMail(detail.value.email, subject, body);

                success.value = true;
            }
        })

        return { closePopup, sendMail, detail, submitted, success };
    }
}
