import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "note mt-4" }
const _hoisted_2 = { class: "mt-6" }
const _hoisted_3 = {
  key: 0,
  class: "mt-8"
}
const _hoisted_4 = { class: "font-xs ml-4 text-center mt-2" }
const _hoisted_5 = { class: "mb-3 mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.detail.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.detail.name) = $event)),
                density: "compact",
                variant: "solo",
                label: "Name",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.detail.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.detail.email) = $event)),
                density: "compact",
                variant: "solo",
                label: "Email id",
                "hide-details": "",
                class: _normalizeClass({ 'border-red': $setup.submitted && ($setup.detail.email.trim() == '' || !$setup.isValidEmail) }),
                onKeyup: _cache[2] || (_cache[2] = ($event: any) => ($setup.validateEmail()))
              }, null, 8, ["modelValue", "class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.detail.phone,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.detail.phone) = $event)),
                density: "compact",
                variant: "solo",
                label: "Phone Number",
                "hide-details": "",
                class: _normalizeClass({ 'border-red': $setup.submitted && ($setup.detail.phone.trim() == '' || !$setup.isValidPh) }),
                onKeyup: _cache[4] || (_cache[4] = ($event: any) => ($setup.validatePhoneNumber()))
              }, null, 8, ["modelValue", "class"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.detail.address,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.detail.address) = $event)),
                density: "compact",
                variant: "solo",
                label: "Address",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: $setup.detail.subject,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.detail.subject) = $event)),
                density: "compact",
                variant: "solo",
                label: "Subject",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                density: "compact",
                variant: "solo",
                label: "Select",
                "hide-details": ""
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                modelValue: $setup.detail.message,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.detail.message) = $event)),
                density: "compact",
                variant: "solo",
                label: "Your Message",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      ($setup.success)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_btn, {
                icon: "mdi-thumb-up",
                color: "success",
                class: "mr-2"
              }),
              _createTextVNode(" Thanks for contacting us. Our Team will get in touch with you shortly. ")
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_btn, {
          class: "btn-primary w-full",
          style: {"width":"100%"},
          onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.sendMail()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submit")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}