
import PlanItem from "@/components/plan/PlanItem.vue";
import planService from '@/services/planService';

export default {
    name: "postpaid-plans",
    components: {
        "jt-plan-item": PlanItem
    },
    setup() {
        const plans = planService.getPopularPlans();

        return { plans };
    }
}
