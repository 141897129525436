
import mailService from '@/services/mailService';
import { ref } from 'vue';

export default {
    name: "plan-details",
    props: {
        item: {
            type: Object
        }
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const success = ref(false);
        const detail = ref({
            name: '',
            phone: '',
            email: '',
            place: '',
            address: ''
        })

        const closePopup = (() => {
            context.emit('closePopup', false)
        })

        const sendMail = (() => {
            submitted.value = true;

            if (detail.value.phone.trim() && detail.value.email.trim() && isValidPh.value && isValidEmail.value) {
                const subject = `${props.item.mode == 1 ? 'Prepaid' : 'Postpaid'} - ${props.item.planname} ${props.item.price}`;
                const body = `${props.item.mode == 1 ? 'Prepaid' : 'Postpaid'} - ${props.item.planname} ${props.item.price} \n
                          Name - ${detail.value.name} \n
                          Phone number - ${detail.value.phone} \n
                          Email - ${detail.value.email} \n
                          Place - ${detail.value.place} \n
                          Address - ${detail.value.address} \n`;

                mailService.sendMail(detail.value.email, subject, body);

                success.value = true;
            }
        });

        const isValidPh = ref(false);
        const validatePhoneNumber = (() => {
            debugger;
            const validationRegex = "^[0-9_.+\\- ]{10,20}$";
            if (detail.value.phone.match(validationRegex)) {
                isValidPh.value = true;
            } else {
                isValidPh.value = false;
            }
        });

        const isValidEmail = ref(false);
        const validateEmail = (() => {
            debugger;
            const validationRegex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
            if (detail.value.email.match(validationRegex)) {
                isValidEmail.value = true;
            } else {
                isValidEmail.value = false;
            }
        });

        return { closePopup, sendMail, detail, submitted, success, validatePhoneNumber, isValidPh, validateEmail, isValidEmail };
    }
}
