
import ImageSlider from '@/components/home/ImageSlider.vue'
import ContactUsForm from "@/components/contactus/ContactUsForm.vue";
import TimeLine from "@/components/contactus/TimeLine.vue";
import NeedGuidance from '@/components/home/NeedGuidance.vue'
import Footer from '@/components/home/Footer.vue'
import { onMounted, ref } from 'vue';

export default {
    name: "contact-us",
    components: {
        "jt-image-slider": ImageSlider,
        "jt-contact-us-form": ContactUsForm,
        "jt-timeline": TimeLine,
        'jt-need-guidance': NeedGuidance,
        'jt-footer': Footer
    },
    setup() {
        const mode = ref('M');

        const setPageTitle = () => {
            document.title = 'Contact';
        };

        onMounted(() => {
            setPageTitle();
        });

        return { mode };
    }
}
