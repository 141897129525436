
import { ref } from 'vue';

export default {
    name: "ImageSlider",
    setup() {
        const items = ref([{
            src: require('../../assets/internettv/1.png'),
            name: 'Easy to use',
            details: ""
        },
        {
            src: require('../../assets/internettv/2.png'),
            name: 'Live Tv Channels',
            details: ""
        },
        {
            src: require('../../assets/internettv/3.png'),
            name: 'HD Clarity',
            details: ""
        },
        {
            src: require('../../assets/internettv/4.png'),
            name: '	Dolby sound',
            details: ""
        }]);

        return { items };
    }
}
