import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_jt_book_plan = _resolveComponent("jt-book-plan")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      class: "btn-primary px-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.showBookNow = true;})
    }, {
      default: _withCtx(() => [
        _createTextVNode("Book Now")
      ]),
      _: 1
    }),
    ($setup.showBookNow)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 0,
          activator: "parent"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_jt_book_plan, {
              onClosePopup: _cache[1] || (_cache[1] = ($event: any) => ($setup.closeBookNow())),
              item: $props.item
            }, null, 8, ["item"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}