
import ImageSlider from '@/components/home/ImageSlider.vue'
import OurServices from '@/components/home/OurServices.vue'
import PopularPlans from '@/components/home/PopularPlans.vue'
import ViewPayBill from '@/components/home/ViewPayBill.vue'
import BookToday from '@/components/home/BookToday.vue'
import MeasureSpeed from '@/components/home/MeasureSpeed.vue'
import TvSmart from '@/components/home/TvSmart.vue'
import NeedGuidance from '@/components/home/NeedGuidance.vue'
import Footer from '@/components/home/Footer.vue'
// import Notification from '@/components/common/Notification.vue'
import { onMounted, ref } from 'vue'

export default {
  name: "HomeView",
  components: {
    "jt-image-slider": ImageSlider,
    "jt-our-services": OurServices,
    'jt-popular-plans': PopularPlans,
    'jt-view-pay-bill': ViewPayBill,
    'jt-book-today': BookToday,
    'jt-measure-speed': MeasureSpeed,
    'jt-tv-smart': TvSmart,
    'jt-need-guidance': NeedGuidance,
    'jt-footer': Footer,
    // 'jt-notification': Notification
  },
  setup() {
    const show = ref(true);

    const closePopup = (() => {
      show.value = false;
    })

    const setPageTitle = () => {
      document.title = 'Jtel | Internet Service Provider in India';
    };

    onMounted(() => {
      setPageTitle();
    });

    return { show, closePopup };
  }
};
