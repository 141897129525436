
import SupportRequest from "@/components/general/SupportRequest.vue";
import { ref } from 'vue';

export default {
    name: "jt-support",
    components: {
        "jt-support-request": SupportRequest
    },
    setup() {
        const show = ref(true);

        return { show };
    }
}
