
import { ref } from 'vue';
import BookPlan from '@/components/plan/BookPlan.vue'

export default {
    name: "book-now-button",
    components: {
        'jt-book-plan': BookPlan
    },
    props: {
        item: {
            type: Object
        }
    },
    setup(props: any, context: any) {
        const showBookNow = ref(false);

        const closeBookNow = (() => {
            showBookNow.value = false;
        });

        const emitBookNow = (() => {
            context.emit('getBookNowStatus', true);
        });

        return { closeBookNow, showBookNow, emitBookNow };
    }
}
