import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import './assets/main.css';
import VueGtag from 'vue-gtag'

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueGtag, {
    config: {
      id: 'AW-1015472130',
      params: {
        send_page_view: true
      }
    },
    includes: [
      {
        id: 'UA-56659067-1',
        params: {
          send_page_view: true
        }
      }
    ]
  }, router)
  .mount("#app");
